<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-3 offset-sm-6">
        <select v-model="tipo_usuario" name="ver_slas" id="ver_slas" class="form-control">
          <option value="1">Valuador</option>
          <option value="2">Notario</option>
        </select>
      </div>
      <div class="col-sm-3">
        <select v-model="ver_slas" name="ver_slas" id="ver_slas" class="form-control">
          <option value="0">Ver todos los sla's</option>
          <option value="1">Ver solo sla's activos</option>
          <option value="2">Ver solo sla's historicos</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div v-for="(sla, index) in slas_mostrar" :key="sla.id" class="col-sm-3 mb-4">
        <div :class="'card'+(sla.estatus == 1 ? ' bg-success' : ' bg-warning')">
          <div class="card-body">
            <h5 class="card-title">{{ buscar_etapa(sla.tipo).etapa }}</h5>
            <div class="card-text">
              <ul>
                <li>Fecha ingreso: {{ $moment(sla.created_at).format('DD MMMM YYYY HH:mm') }}</li>
                <li>Estatus: {{ sla.estatus == 1 ? 'Activo' : 'Inactivo' }}</li>
                <li>Estatus: {{ sla.tipo_usuario }}</li>
              </ul>
              <template v-if="sla.estatus != 1">
              <h5>Cancelación</h5>
              <ul>
                <li>Razón: {{ sla.razon_cancelacion}}</li>
                <li>Tiempo: {{ $moment(sla.cancel_at).format('DD MMMM YYYY HH:mm') }}</li>
              </ul>
              </template>

              <div v-if="sla.estatus == 1 && sla.id == primer_sla" class="text-right">
                <button class="btn btn-danger" @click="retroceder_etapa=true">Retroceder etapa</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Retroceder v-if="retroceder_etapa" :solicitud="solicitud" :tipo_usuario="tipo_usuario" @cancelar="retroceder_etapa=false" @actualizar="cerrar_retroceder_actualizar" />
  </div>
</template>

<script type="text/javascript">
  import apiEtapas from '@/apps/formalizacion/api/v2/etapas'

  import Retroceder from '@/apps/formalizacion/Componentes/Retroceder'

  export default {
    components: {
      Retroceder
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,sla: []
          }
        }
      }
    }
    ,data() {
      return {
        etapas: []
        ,detalle_activo: null
        ,ver_slas: 0
        ,tipo_usuario: 1
        ,retroceder_etapa: false
      }
    }
    ,mounted() {
      this.obtener_etapas();
    }
    ,methods: {
      async obtener_etapas() {
        try {
          this.etapas = await this.$helper.cache_store('formalizacion.etapas',async () => {
            return (await apiEtapas.obtener_etapas()).data;
          });
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_etapa(tipo) {
        let etapa = {etapa: null};

        for(let i=0; i<this.etapas.length; i++) {
          if (this.etapas[i].codigo == tipo) {
            etapa = this.etapas[i];
            i=this.etapas.length;
          }
        }

        // etapa = this.$helper.decode_utf8(etapa);

        return etapa;
      }
      ,busar_estatus(estatus) {
        switch(estatus) {
          case 1:
            return 'Activo';
            break;
          case 2:
            return 'Historico';
            break;
          default:
            return 'Sistema';
            break;
        }
      }
      ,buscar_tipo(tipo) {
        switch(tipo) {
          case 1:
            return 'Valuador';
            break;
          case 2:
            return 'Notario';
            break;
          case 0:
            return 'Sistema';
            break;
        }
      }
      ,ver_detalles(index) {
        let detalle = this.$refs['detalles_'+index][0];

        detalle.style.display = 'block';

        let sla = this.$refs['sla_'+index][0];

        sla.classList.add('activo');

        if (this.detalle_activo !== null) {
          let detalle_old = this.$refs['detalles_'+this.detalle_activo][0];
          detalle_old.style.display = 'none';

          let sla = this.$refs['sla_'+this.detalle_activo][0];

          sla.classList.remove('activo');
        }

        this.detalle_activo = index;
      }
      ,cerrar_retroceder_actualizar() {
        this.retroceder_etapa = false;
        this.$emit('update');
      }
    }
    ,computed: {
      slas_mostrar() {
        let slas = [];

        if (!this.solicitud.sla)
          return [];

        if (this.ver_slas != 0)
          this.solicitud.sla.forEach(sla => {
            if (sla.tipo_usuario == this.tipo_usuario)
              if (sla.estatus == this.ver_slas)
                slas.push(sla);
          })
        else
          this.solicitud.sla.forEach(sla => {
            if (sla.tipo_usuario == this.tipo_usuario)
              slas.push(sla);
          })

        if (slas.length > 0)
          slas.sort((a, b) => {
            if (a.id > b.id)
              return -1;
          })

        return slas;
      }
      ,primer_sla() {
        let sla = null
        let slas = this.slas_mostrar;

        for(let i=0; i<slas.length; i++) {
          if (slas[i].estatus == 1 && !sla) {
            sla = slas[i];
            i=slas.length;
          }
        }

        return sla.id;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>