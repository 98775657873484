import { render, staticRenderFns } from "./Sla.vue?vue&type=template&id=07b198e3&scoped=true"
import script from "./Sla.vue?vue&type=script&lang=js"
export * from "./Sla.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b198e3",
  null
  
)

export default component.exports